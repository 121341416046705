<template>
  <v-container fluid>
    <Loading :loading="!loaded" />

    <div v-if="loaded">
      <v-row>
        <v-col>
          <!-- ---- main card ---- -->
          <v-card
            elevation="4"
            outlined
          >
            <v-toolbar flat>
              <v-toolbar-title>
                {{ $t('account.title') }}
              </v-toolbar-title>

              <v-spacer />

              <div class="d-flex justify-space-between" style="gap: 1rem;">
                <v-btn
                  outlined
                  color="primary"
                  @click="cancel"
                >
                  Annuller
                </v-btn>
                <v-btn
                  color="secondary"
                  :loading="saving"
                  @click="save"
                >
                  Gem
                </v-btn>
              </div>
            </v-toolbar>
            <v-card-text>
              <v-container
                v-if="model"
                fluid
              >
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      v-model="model.data.firstName"
                      :label="t('account.firstNameLabel')"
                    />
                    <v-text-field
                      v-model="model.data.office"
                      :label="t('account.officeLabel')"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="model.data.lastName"
                      :label="t('account.lastNameLabel')"
                    />
                    <v-text-field
                      v-model="model.data.title"
                      :label="t('account.titleLabel')"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="model.data.email"
                      class="mt-3"
                      outlined
                      dense
                      :label="t('account.emailLabel')"
                    />
                    <div style="margin-top: -32px;">
                      <label class="v-text-field v-input v-label text-caption">
                        {{ $t('account.telePhoneLabel') }}
                      </label>
                      <vue-tel-input
                        v-bind="bindTelInputPhoneProps"
                        ref="phone"
                        v-model="model.data.phone"
                      />
                    </div>
                    <div>
                      <label class="v-text-field v-input v-label text-caption">
                        {{ $t('account.cellPhoneLabel') }}
                      </label>
                      <vue-tel-input
                        v-bind="bindTelInputCellPhoneProps"
                        ref="phone"
                        v-model="model.data.cellPhone"
                      />
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <h3>{{ $t('account.notificationPreferencesTitle') }}</h3>
                    <span class="caption">
                      {{ $t('account.notificationPreferencesSubtitle') }}
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-simple-table dense>
                      <template #default>
                        <tbody>
                          <tr
                            v-for="(np,i) in model.data.notificationPreferences"
                            :key="i"
                          >
                            <th>{{ np.text }}</th>
                            <td>
                              <v-checkbox
                                v-model="np.sendMail"
                                :label="t('account.checkboxSendEmail')"
                              />
                            </td>
                            <td>
                              <v-checkbox
                                v-model="np.sendSMS"
                                :label="t('account.checkboxSendSMS')"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <v-snackbar
      v-model="saveSuccess"
      timeout="2000"
    >
      Ændringerne blev gemt
    </v-snackbar>
  </v-container>
</template>

<script>

import { VueTelInput } from 'vue-tel-input'
import Loading from '@/components/Loading'

var searchTimeout = null;
  
export default {  
  name: 'Account',
  components: {
    VueTelInput,
    Loading
  },
  data() {
      return {
      model: null,
      //view only
      loaded: false,
      saving: false,
      saveSuccess: false,
      t : this.$t,
      bindTelInputPhoneProps: {
        //https://educationlink.github.io/vue-tel-input/documentation/props.html
        mode: "international", //Format number to 'international' (with + dial code) or 'national' (with 0...)
        defaultCountry: "dk", //Default country, will override the country fetched from IP address of user
        autoDefaultCountry: false,
        validCharactersOnly: true, //only numbers
        preferredCountries: ['dk'],
        name: "telephone",
        inputOptions: {
            name: "telephoneinput",
            placeholder: this.$t('account.telePhoneLabel')
        }
      },
      bindTelInputCellPhoneProps: {
        //https://educationlink.github.io/vue-tel-input/documentation/props.html
        mode: "international", //Format number to 'international' (with + dial code) or 'national' (with 0...)
        defaultCountry: "dk", //Default country, will override the country fetched from IP address of user
        autoDefaultCountry: false,
        validCharactersOnly: true, //only numbers
        preferredCountries: ['dk'],
        name: "cellphone",
        inputOptions: {
            name: "cellphoneinput",
            placeholder: this.$t('account.cellPhoneLabel')
        }
      },      
    }
  },
  created() {
    var vm = this;
    vm.init();
  },
  methods: {
    init: function() {
      var vm = this;
      vm.$AuthService.api.get(`user/edit` )
        .then(response => {
          this.model = response.data;
          this.loaded = true;
      });
    },
    save: function() {
      var vm = this;
      vm.saving = true;

      vm.$AuthService.api.put(`user/edit`, vm.model.data)
      .then(response => {
        if (response.data.success) {
          //vm.model = response.data;
          vm.saving = false;
          vm.saveSuccess = true;
          vm.init();
        }
        else {
          //handle...
        }
      });
    },
    cancel: function() {
      this.init();
    }
  }
}
</script>
